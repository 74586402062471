import React from 'react'
import HeroGradient from 'components/hero-image/hero-image-gradient'
import { Helmet } from 'react-helmet'
import Promos from 'components/promos'
import Floater from 'components/floating-grid'
import Content from 'components/category/content'
import Videos from 'components/category/videos'
import Layout from 'components/layout'
import { graphql } from 'gatsby'

const items = [
  {
    link: '/agriculture/tillage/field-cultivators-mulch-finishers/',
    imageId: 'cultivatorsMulchFinishersImage',
    name: 'Field Cultivators & Mulch Finishers',
    alt: 'John Deere Field Cultivators and Mulch Finishers',
  },
  {
    link: '/agriculture/tillage/rippers/',
    imageId: 'rippersImage',
    name: 'Rippers',
    alt: 'John Deere Rippers',
  },
  {
    link: '/agriculture/tillage/chisel-plows/',
    imageId: 'chiselPlowsImage',
    name: 'Chisel Plows',
    alt: 'John Deere Chisel Plows',
  },
  {
    link: '/agriculture/tillage/moldboard-plows/',
    imageId: 'moldboardPlowsImage',
    name: 'Moldboard Plows',
    alt: 'John Deere Moldboard Plows',
  },
  {
    link: '/agriculture/tillage/mulch-tillers/',
    imageId: 'mulchTillersImage',
    name: 'Mulch Tillers',
    alt: 'John Deere Mulch Tillers',
  },
  {
    link: '/agriculture/tillage/disks/',
    imageId: 'disksImage',
    name: 'Disks',
    alt: 'John Deere Disks',
  },
]

const TillagePage = ({ data }) => {
  const { allHutsonPromotion, allDeereOffer, heroImage } = data
  const promos = []
  if (allHutsonPromotion) {
    allHutsonPromotion.nodes.forEach(node => promos.push(node))
  }
  if (allDeereOffer) {
    allDeereOffer.nodes.forEach(node => promos.push(node))
  }
  const gridItems = items.map(item => {
    item.image = data[item.imageId]
    return item
  })
  return (
    <Layout>
      <Helmet>
        <title>John Deere Tillage Equipment | Hutson Inc</title>
        <meta
          name='description'
          content="John Deere tillage is equipment is the best in the industry. They're made with the latest technology to ensure the most efficient experience. Learn more at Hutson Inc."
        />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': [
              {
                '@type': 'ListItem',
                'position': 1,
                'name': 'Hutson Inc',
                'item': 'https://www.hutsoninc.com/',
              },
              {
                '@type': 'ListItem',
                'position': 2,
                'name': 'Agriculture Equipment',
                'item': 'https://www.hutsoninc.com/agriculture/',
              },
              {
                '@type': 'ListItem',
                'position': 3,
                'name': 'Tillage',
                'item': 'https://www.hutsoninc.com/agriculture/tillage/',
              },
            ],
          })}
        </script>
      </Helmet>

      <HeroGradient image={heroImage} heading='Tillage Equipment' />

      <Content>
        <Floater items={gridItems} />

        <Promos data={promos} type='Tillage Equipment' />

        <Videos
          type='Tillage Equipment'
          id='Sn0lKG9elIw'
          list='PLUz5fj7f_mw9k9smtR0ZBv9bN4vDFCCFP'
        />
      </Content>
    </Layout>
  )
}

export const pageQuery = graphql`
  query tillageSubcategoryQuery($subcategory: String = "tillage") {
    allHutsonPromotion(
      filter: {
        categoryList: { elemMatch: { category: { eq: $subcategory }, type: { eq: "john-deere" } } }
      }
      sort: { fields: [endDate], order: DESC }
      limit: 3
    ) {
      nodes {
        ...HutsonPromo
      }
    }
    allDeereOffer(filter: { subcategoryList: { in: [$subcategory] } }, limit: 3) {
      nodes {
        ...DeerePromo
      }
    }
    heroImage: file(relativePath: { eq: "headers/tillage-subcategory-header.jpg" }) {
      ...FullWidthImage
    }
    cultivatorsMulchFinishersImage: file(
      relativePath: { eq: "agriculture/field-cultivators-mulch-finishers.jpg" }
    ) {
      ...FloatingGridImage
    }
    rippersImage: file(relativePath: { eq: "agriculture/rippers.jpg" }) {
      ...FloatingGridImage
    }
    chiselPlowsImage: file(relativePath: { eq: "agriculture/chisel-plows.jpg" }) {
      ...FloatingGridImage
    }
    moldboardPlowsImage: file(relativePath: { eq: "agriculture/moldboard-plows.jpg" }) {
      ...FloatingGridImage
    }
    mulchTillersImage: file(relativePath: { eq: "agriculture/mulch-tillers.jpg" }) {
      ...FloatingGridImage
    }
    disksImage: file(relativePath: { eq: "agriculture/disks.jpg" }) {
      ...FloatingGridImage
    }
  }
`

export default TillagePage
